import type { GetHotelLayoutQuery } from '../generated/types';
import { baseUrl, HotelPage } from './constants';
import type { GetServerSidePropsContext } from 'next';
import type { NextRouter } from 'next/router';

type GetLinksProps = {
  hotelSlug?: string;
  items?: NonNullable<GetHotelLayoutQuery['hotelPageTemplate']>['navigation'];
  lang: string;
  page: string;
};

export type TNavLink = {
  title: string;
  title_noTx: string;
  href: string;
  isNewWindow?: boolean;
  isShallow?: boolean;
  isSelected?: boolean;
  isPrefetch?: boolean;
};

type RedirectInvalidPathProps = {
  lang: string;
  hotelSlug?: string;
  validPath: string;
  req: GetServerSidePropsContext['req'];
  res: GetServerSidePropsContext['res'];
};

type GetRouteParamsResult = {
  ctyhocn: string;
  hotelSlug: string;
  lang: string;
  wifi: boolean;
};

export const GetNavLinks = ({ items = [], hotelSlug, lang, page }: GetLinksProps): TNavLink[] =>
  items.map((link) => {
    const hrefPage = link?.url?.replace(
      /http[s]:\/\/[a-z0-9*\-.]*\/[a-z0-9*\-.]*\/hotels*\/[a-z0-9*\-.]*\//,
      ''
    );
    const hrefPath = hrefPage ? hrefPage.replace(/\/$/, '') : 'home';
    const baseHotelUrl = `${baseUrl}/${[lang, 'hotels', hotelSlug].join('/')}`;
    const isShallow = link?.url?.startsWith(baseHotelUrl);
    const shallowUrl = `/${[lang, 'hotels', hotelSlug, hrefPage].join('/')}`;

    return {
      title: link.name,
      title_noTx: link.name_noTx,
      href: isShallow ? shallowUrl : link?.url,
      isSelected: page === hrefPath,
      isShallow,
      isPrefetch: hrefPath.includes(HotelPage.HOME) || hrefPath.includes(HotelPage.ROOMS),
    };
  });

/**
 * Handles redirects at the app level with 301 (Moved Permanently) redirects
 * based on the homeUrl field coming from the Graph (Core+) response.
 * --> homeUrl      will either be the full OWH URL or an external URL in the
 *                  case where "propertyUrl" is set in CMS
 * --> homeUrlPath  will be null when "propertyUrl" in CMS has an entry
 *                  in which case OHW should redirect to homeUrl
 *
 * Related tickets:
 * - OHWPR-2258: "Property Pages Should Redirect Based on Property URL Field in
 *   CMS (Property FLLDHQQ loading on OHW instead of redirecting to GW)"
 * - OHWPR-2247: "Redirect 'Hilton Ras Al Khaimah Beach Resort' to Global Web"
 *
 * Note: Since these redirects are handled in the CMS, they will automatically
 *       be removed when these properties move to new Curated (Complex) sites.
 * */
export const redirectBasedOnHomeUrlSS = ({
  homeUrl,
  homeUrlPath,
  res,
}: {
  homeUrl: string;
  homeUrlPath: string;
  res: GetServerSidePropsContext['res'];
}) => {
  if (!homeUrlPath) {
    // Note: redirect does not exist on GetServerSidePropsContext['res'] - we may want to use writeHead
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    res.redirect(301, homeUrl);
  }
};

/* If hotelSlug url param does not match homeUrlPath, redirect.
   ex:
    - rldv-dt --> rldv-dt-doubletree-denver,
    - /densndt-doubletree-denver-stapleton-north/ -->  /densndt-doubletree-denver-central-park/  (OHWPR-2267)
   */

export const redirectInvalidPathSS = ({
  hotelSlug,
  lang,
  req,
  res,
  validPath,
}: RedirectInvalidPathProps) => {
  const requestedPath = `/${lang}/hotels/${hotelSlug}/`;
  const localessRequestedPath = requestedPath.slice(requestedPath.indexOf('/', 1));
  const localelessValidPath = validPath.slice(validPath.indexOf('/', 1));
  if (localessRequestedPath !== localelessValidPath) {
    // rooms example to illustrate how urlSplit works
    const fullURL = `/${lang}${req.url}`; // /en/rlvt-dt/rooms/
    const validLocalePath = `/${lang}${localelessValidPath}`; // /en/rlvt-dt-doubletree-denver/
    console.log(`Redirecting FROM: "${requestedPath}" TO: "${validLocalePath}"`); // eslint-disable-line no-console
    const urlSplitArray = fullURL.split(requestedPath); // ['/en', 'rooms/']
    // Note: redirect does not exist on GetServerSidePropsContext['res'] - we may want to use writeHead
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    res.redirect(301, validLocalePath + (urlSplitArray[1] || '')); // /en/rlvt-dt-doubletree-denver/rooms/
  }
};

// check for valid slug (after 7 char cythocn) - only letters, numbers and hyphens, & must start with hyphen (if over 7 chars)
export const IsValidCtyhocnSlug = (slug: string): boolean => {
  const slugAfterCtyhocn = slug.substr(7);
  return slugAfterCtyhocn ? /^-[A-Z0-9-]*$/i.test(slugAfterCtyhocn) : true;
};

export const getCtyhocnFromSlug = (slug: string | string[] = ''): string => {
  const slugString = Array.isArray(slug) ? slug.join('') : slug;
  const match = slugString.match(/^([A-Z]{4}-[A-Z]{2}|[A-Z]{7})-?.*/i);
  return match?.[1].toUpperCase() || '';
};

const GetStringFromQuery = (input: string | string[] = ''): string =>
  input === 'undefined'
    ? ''
    : ((Array.isArray(input) && input.length > 0 ? input[0] : input) as string) || '';

export const getHotelsRouteParams = (router: NextRouter): GetRouteParamsResult => {
  const lang = GetStringFromQuery(router?.query?.language) || '';
  const hotelSlug = GetStringFromQuery(router?.query?.hotelSlug) || '';
  const ctyhocn = IsValidCtyhocnSlug(hotelSlug) ? getCtyhocnFromSlug(hotelSlug) : '';
  const wifi = router?.query?.wifi === 'true';

  return {
    ctyhocn,
    hotelSlug,
    lang,
    wifi,
  };
};
