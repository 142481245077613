import type { FormDefaultValues } from '@dx-ui/osc-shop-form';
import {
  useShopFormContext,
  wrapperClassNameDefault,
  ShopForm,
  ShopFormDates,
  ShopFormGroup,
  ShopFormRooms,
  ShopFormSpecialRates,
} from '@dx-ui/osc-shop-form';
import cx from 'classnames';
import { baseUrl } from '../../helpers/constants';
import { addDays, isBefore } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { sendRewardAsync } from '@dx-ui/framework-conductrics';
import type { TSearchState } from '../../helpers/providers/search/search-provider';
import { useSearchContext } from '../../helpers/providers/search/search-provider';
import { useRouter } from 'next/router';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { useIsClient } from 'usehooks-ts';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { useState, useEffect } from 'react';
import { GOALS } from '../../helpers/conductricsConstants';
import type { LayoutData } from '../../helpers/layout.types';

type TSearchWidget = {
  ctyhocn: string;
  isGroupSearch: boolean;
  showNumAttendees: boolean;
  isResEnabled: boolean;
  resEnabledDate: string;
  isOpen: boolean;
  openDate: string;
  ageBasedPricing: LayoutData['ageBasedPricing'];
  isAdultsOnly: boolean;
  adultAge: LayoutData['adultAge'];
  isPartnerBrand: boolean;
  brandCode: string;
  maxOccupants: number;
  maxNumRooms: number;
  hideFlexDates: boolean;
};

const SearchWidget = ({
  ctyhocn,
  isGroupSearch,
  showNumAttendees,
  isResEnabled,
  resEnabledDate,
  isOpen,
  openDate,
  ageBasedPricing,
  isAdultsOnly,
  adultAge,
  isPartnerBrand,
  brandCode,
  maxOccupants,
  maxNumRooms,
  hideFlexDates,
}: TSearchWidget) => {
  const { searchState, onSearchChange, initializeSearchState } = useSearchContext();
  const language = useRouter().locale || 'en';
  const { guestInfo } = useAuth();
  const { t } = useTranslation('dx-search');
  const isClient = useIsClient();

  const getStartDate = () => {
    if (!isOpen) {
      if (isResEnabled) {
        return new Date(`${resEnabledDate}T00:00`);
      } else {
        return new Date(`${openDate}T00:00`);
      }
    }

    if (isGroupSearch) {
      return addDays(new Date(Date.now()), 7);
    }
    return new Date(Date.now());
  };

  const day: Date = getStartDate();
  const searchStateArrivalDate = searchState?.dates?.arrivalDate
    ? new Date(searchState?.dates.arrivalDate)
    : null;
  const searchStateDepartureDate = searchState?.dates?.departureDate
    ? new Date(searchState?.dates.departureDate)
    : null;

  const arrivalDate =
    searchStateArrivalDate && !isBefore(searchStateArrivalDate, day) ? searchStateArrivalDate : day;

  const departureDate =
    searchStateDepartureDate && !isBefore(searchStateDepartureDate, day)
      ? searchStateDepartureDate
      : addDays(day, isGroupSearch ? 2 : 1);

  const defaultValues: FormDefaultValues = {
    brandCode,
    dates: {
      ...searchState?.dates,
      arrivalDate,
      departureDate,
    },
    rooms: searchState?.rooms,
    hhonors: guestInfo?.hhonors,
    query: ctyhocn,
    numAttendees: isGroupSearch ? searchState?.numAttendees || 0 : null,
    numRooms: isGroupSearch ? searchState?.numRooms ?? 10 : null,
    meetingSpace: searchState?.meetingSpace ?? false,
    specialRates: searchState?.specialRates,
  };

  const searchFormKey = isBrowser
    ? window.btoa(encodeURI(JSON.stringify(defaultValues)))
    : 'search-form';

  if (!isClient) {
    return null;
  }

  return (
    <ShopForm
      defaultValues={defaultValues}
      language={language}
      wrapperClassName={cx([...wrapperClassNameDefault, 'w-fit'].slice(1))}
      submitOpensNewTab={true}
      targetOHWPage="book"
      key={searchFormKey}
      cta={t('ctaButton')}
      hasErrorBanner={false}
      onSubmit={async ({ url }) => {
        await sendRewardAsync(GOALS.EDIT_SEARCH_WIDGET);
        window.open(baseUrl + url, '_blank', 'noopener');
      }}
    >
      <SearchWidgetForm
        language={language}
        onSearchChange={onSearchChange}
        initializeSearchState={initializeSearchState}
        isGroupSearch={isGroupSearch}
        showNumAttendees={showNumAttendees}
        searchState={searchState || defaultValues}
        ageBasedPricing={ageBasedPricing}
        isAdultsOnly={isAdultsOnly}
        adultAge={adultAge}
        isPartnerBrand={isPartnerBrand}
        maxOccupants={maxOccupants}
        maxNumRooms={maxNumRooms}
        hideFlexDates={hideFlexDates}
      />
    </ShopForm>
  );
};

type TSearchWidgetForm = {
  language: string;
  searchState: TSearchState;
  onSearchChange: ReturnType<typeof useSearchContext>['onSearchChange'];
  initializeSearchState: ReturnType<typeof useSearchContext>['initializeSearchState'];
  isGroupSearch: boolean;
  showNumAttendees?: boolean;
  ageBasedPricing: LayoutData['ageBasedPricing'];
  isAdultsOnly: boolean;
  adultAge: LayoutData['adultAge'];
  isPartnerBrand: boolean;
  maxOccupants: number;
  maxNumRooms: number;
  hideFlexDates: boolean;
};

type ModalType = 'dates' | 'rooms' | 'specialRates' | null;
const MODAL_SESSION_KEY = 'property_search_widget_modal';

const SearchWidgetForm = ({
  language,
  searchState,
  onSearchChange,
  initializeSearchState,
  isGroupSearch,
  showNumAttendees,
  ageBasedPricing,
  isAdultsOnly,
  adultAge,
  isPartnerBrand,
  maxOccupants,
  maxNumRooms,
  hideFlexDates,
}: TSearchWidgetForm) => {
  const { t } = useTranslation('osc-rooms');
  const { watch } = useShopFormContext();
  const canOnlyBookSingleRoom = maxNumRooms === 1;

  const formState = watch();
  const hasTodayAsDefault = !!(
    searchState?.dates?.arrivalDate && searchState?.dates?.departureDate
  );
  const [activeModal, setActiveModal] = useState<ModalType>(
    () => (window?.sessionStorage?.getItem(MODAL_SESSION_KEY) as ModalType) || null
  );

  const ageRange =
    ageBasedPricing && adultAge
      ? {
          max: adultAge,
          min: 0,
        }
      : undefined;
  const agesRequired = !!ageRange;

  useEffect(() => {
    initializeSearchState(formState);
  }, [formState, initializeSearchState]);

  useEffect(() => {
    if (
      searchState?.numAttendees !== formState?.numAttendees ||
      searchState?.numRooms !== formState?.numRooms
    ) {
      onSearchChange(formState, { skipStateUpdate: true });
    }
  }, [formState, onSearchChange, searchState?.numAttendees, searchState?.numRooms]);

  const handleModalOpen = (type: ModalType) => {
    window?.sessionStorage?.setItem(MODAL_SESSION_KEY, String(type));
    setActiveModal(type);
  };

  const handleDismiss = () => {
    window?.sessionStorage?.setItem(MODAL_SESSION_KEY, '');
    setActiveModal(null);
  };

  const handleConfirmAndClose = () => {
    onSearchChange(formState);
    window?.sessionStorage?.setItem(MODAL_SESSION_KEY, '');
    setActiveModal(null);
  };

  const occupancyLimitMessage = canOnlyBookSingleRoom
    ? t('occupancy.occupancySingleRoomLimitMessage')
    : t('occupancy.occupancyLimitMessage');

  return (
    <>
      <ShopFormDates
        hasTodayAsDefault={hasTodayAsDefault}
        hideFlexDates={hideFlexDates}
        language={language}
        isOpen={activeModal === 'dates'}
        onConfirm={handleConfirmAndClose}
        onOpen={() => handleModalOpen('dates')}
        onDismiss={handleDismiss}
      />
      {isGroupSearch ? (
        <ShopFormGroup showNumAttendees={showNumAttendees} />
      ) : (
        <>
          <ShopFormRooms
            occupancyLimitMessage={occupancyLimitMessage}
            ageRange={ageRange}
            open={activeModal === 'rooms'}
            onConfirm={handleConfirmAndClose}
            onOpen={() => handleModalOpen('rooms')}
            onDismiss={handleDismiss}
            agesRequired={agesRequired}
            adultAge={adultAge ?? undefined}
            adultsOnly={isAdultsOnly}
            hideGroupLink={isPartnerBrand}
            maxRooms={maxNumRooms}
            maxOccupants={maxOccupants}
          />
          <ShopFormSpecialRates
            open={activeModal === 'specialRates'}
            onConfirm={handleConfirmAndClose}
            onOpen={() => handleModalOpen('specialRates')}
            onDismiss={handleDismiss}
          />
        </>
      )}
    </>
  );
};
export default SearchWidget;
